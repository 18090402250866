<!-- 消息通知 -->
<!--页面元素-->
<template>
  <el-main>
    <page-title />
    <div class="tabs-inner message-list">
      <el-tabs v-model="activeName">
        <search-inner @submit-search="search" @clear-search="reset">
          <el-col :span="6">
            <el-form-item label="消息类型：">
              <el-select v-model="searchForm.informationType" placeholder="请选择消息类型">
                <el-option label="全部" :value="0" />
                <el-option v-for="item in constants.messageType" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="创建人：">
              <el-input v-model="searchForm.createdBy" placeholder="请输入要搜索的创建人" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="发送时间">
              <ics-search-date :date.sync="createdArr" />
            </el-form-item>
          </el-col>
        </search-inner>
        <table-inner title="消息列表" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :page-id="1" class="customer-no-border-table" @changePageNum="changePageNum($event)">
          <template slot="btn-inner">
            <debounce-button :loading="loading.submit" style="margin-right: 5px;" type="primary" @click="wholeRead">
              全部已读
            </debounce-button>
          </template>
          <template slot="table-bd">
            <el-table :stripe="false" :data="tableList.list">
              <el-table-column prop="informationContent" :formatter="utils.isEffective" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div class="message-item" :class="scope.row.status !== 0 ? 'read': ''" @click="read(scope.row, scope.$index)">
                    <img v-if="scope.row.status === 0" src="../../../assets/images/message.png" class="message-icon">
                    <img v-if="scope.row.status !== 0" class="message-icon" src="../../../assets/images/message-open.png">
                    <div>
                      <a v-if="scope.row.status === 0" href="javascript:;" @click="goPage(scope.row)">
                        <span><p class="show_length"><b>{{ utils.formatHtmlLabel(scope.row.informationContent) }}</b></p>
                          <p style="margin-top: 5px;margin-left: 10px;">
                            <span>发送时间: {{ scope.row.createdAt }}</span>
                            <span style="margin-left: 40px;">消息类型: {{ utils.formatMessageType(Number(scope.row.informationType)) }}</span>
                            <span style="margin-left: 90px;">创建人: {{ scope.row.createdBy }}</span>
                          </p>
                        </span>
                      </a>
                      <a v-if="scope.row.status !== 0" style="opacity: 0.5;" href="javascript:;" @click="goPage(scope.row)">
                        <span><p class="show_length">{{ utils.formatHtmlLabel(scope.row.informationContent) }}</p>
                          <p>
                            <span>  发送时间: {{ scope.row.createdAt }}</span>
                            <span style="margin-left: 40px;">消息类型: {{ utils.formatMessageType(Number(scope.row.informationType)) }}</span>
                            <span style="margin-left: 90px;">创建人: {{ scope.row.createdBy }}</span>
                          </p>
                        </span>
                      </a>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </table-inner>
      </el-tabs>
    </div>
  </el-main>
</template>
<!--模块化-->
<script>
import IcsSearchDate from '@/components/search-date'
import utils from '@/assets/js/utils'
export default {
  components: { IcsSearchDate },
  data () {
    return {
      activeName: 'check',
      searchForm: {
        informationType: '',
        createdBy: '',
        createdBeginAt: '',
        createdEndAt: ''
      },
      loading: {
        submit: false
      },
      tableList: {
        pageNum: 1, // 当前页
        list: [] // 表格数据
      }
    }
  },
  computed: {
    createdArr: utils.computedDate('createdBeginAt', 'createdEndAt')
  },
  // 周期，初始化
  created () {
    this.getList()
  },
  // 事件方法
  methods: {
    // 请求数据
    getList () {
      this.api.workbench.pageList(this.searchForm).then(result => {
        this.tableList = result.data.data
      }).catch(e => {
      })
    },
    read (item, index) {
      this.api.workbench.readById(item.id).then(result => {
        this.tableList.list[index].status = 1
      }).catch(e => {
      })
    },
    search () {
      this.getList()
    },
    reset () {
      this.searchForm.informationType = ''
      this.searchForm.createdBy = ''
      this.searchForm.createdBeginAt = ''
      this.searchForm.createdEndAt = ''
      this.getList()
    },
    // 切换分页
    changePageNum (e) {
      if (typeof e.pageNum !== 'undefined' && e.pageNum !== null) {
        this.searchForm.pageNum = e.pageNum
      }
      this.getList()
    },
    countMsg () {
      this.api.workbench.countMsg().then(result => {
        this.$store.commit('updateQuantity', result.data.data)
      })
    },
    wholeRead () {
      this.loading.submit = true
      this.api.workbench.readAll().then(result => {
        if (result.data.success === true) {
          this.$message.success('操作成功')
          this.loading.submit = false
          this.countMsg()
          this.getList()
        } else {
          this.$message.success(result.data.message)
          this.loading.submit = false
        }
      }).finally(() => {
        this.loading.submit = false
      })
    },
    goPage (row) {
      this.$router.push({
        name: 'messageDetail',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>
<!--样式-->
<style lang="less" scoped>
.message-list {
  .read {
    opacity: 0.6;
    background:rgba(246,246,246,0.5);
  }
  .message-item {
    cursor:pointer;
    margin: 3px;
    padding: 10px 5px;
    background-color: #f6f6f6;
    border-radius: 10px;
    box-shadow:5px 5px 3px #dbdbdb;
    .message-icon {
      vertical-align: middle;
      width: 24px;
    }
    span {
      vertical-align: middle;
      display: inline-block;
      word-wrap: break-word;
      word-break: break-all;
      white-space: pre-wrap !important;
    }
    >div {
      vertical-align: middle;
      display: inline-block;
      width: 80%;
      >a {
        margin-left: 5px;
      }
    }
    >span:nth-child(3) {
      width: 15%;
      text-align: right;
    }
  }
}
.show_length{
  width:500px;
  text-overflow :ellipsis;
  white-space :nowrap;
  overflow : hidden;
}
</style>
